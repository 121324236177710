import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { colors, Grid, Cell } from '../theme';
import ButtonLink from './Buttons';
import GatsbyImage from 'gatsby-image';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-bottom: 10rem;
`;

const HorizentalLine = styled.hr`
  border-top: 0.1rem solid #dddddd;
`;

const ArticleContainer = styled.div`
  padding: 3rem 2rem;
  border-bottom: none;
  margin-bottom: 4rem;
`;

const PostHeading = styled.div`
  margin-bottom: 1.5rem;
  padding: 2rem;
`;

const PostTitle = styled.div`
  font-weight: 900;
  font-size: 3rem;
  color: ${colors.gray.headerItemColor};
`;

const BlogFigure = styled.figure`
  ${(props) => props.bgUrl && `background-image: url(${props.bgUrl})`}
  padding-bottom: 50%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1rem;
`;

const PostContent = styled.p`
  padding: 1rem 0;
  text-align: justify;
  strong {
    font-weight: 700;
    font-size: 2.2rem;
    color: ${colors.blue.blogBtn};
  }
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2.2rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.8rem;
  }
`;

const BlogPost = (props) => {
  return (
    <SectionLayout>
      <Container>
        <HorizentalLine></HorizentalLine>
        <Grid rowGap={'1.5rem'} rows={1} columns={1}>
          <Cell>
            <ArticleContainer>
              <PostHeading>
                <PostTitle>{props.title}</PostTitle>
              </PostHeading>
              {props.date && (
                <ButtonLink
                  title={new Date(props.date).toLocaleDateString('fa-IR')}
                  bgColor={colors.blue.blogBtn}
                  bgColorIcon={colors.blue.blogBtn}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="calendar-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="white"
                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                    ></path>
                  </svg>
                </ButtonLink>
              )}
              {props.img ? (
                <GatsbyImage
                  fluid={props.img}
                  imgStyle={{ objectFit: 'cover' }}
                />
              ) : (
                <BlogFigure bgUrl={props.bgUrl} />
              )}
              <PostContent
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            </ArticleContainer>
          </Cell>
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default BlogPost;
