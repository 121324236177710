import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import BlogPost from '../components/Blog-post';
import { endpoints } from '../theme';
import HashLoader from 'react-spinners/HashLoader';
import { Container } from 'styled-bootstrap-grid';
import { graphql } from 'gatsby';

const BlogDetails = ({ data }) => {
  const post = data.wordpressPost;

  return (
    <Layout>
      {post ? (
        <BlogPost
          title={post.title}
          content={post.content}
          bgUrl={post.featured_media.localFile?.publicURL}
          img={post.featured_media.localFile.childImageSharp.fluid}
          date={post.date}
        />
      ) : (
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            padding: '10rem 0',
          }}
        >
          <HashLoader size={150} color={'#304970'} />
        </Container>
      )}
    </Layout>
  );
};

export default BlogDetails;

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      excerpt
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400, maxHeight: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
